import { cloneElement } from 'react';
import { useRouter } from 'next/router';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { match } from 'ts-pattern';

import {
  AppContainer,
  Icon,
  IconProps,
  TabsContent,
  TabsList,
  useGradient,
  useTabsContext,
} from '@arena-labs/strive2-ui';
import { $API, useUserProfile } from '@strive/api';

import { LaunchpadFooter } from '../launchpad/launchpad-footer';
import { useNeedsSleepNeedsACI } from '../progress/use-needs-nights-sleep';
import { AppFooter } from './app-footer';

export type AppLayoutProps = {
  children: React.ReactNode;
  fixedHeight?: boolean;
  footer?: React.ReactElement | false;
  contentProps?: BoxProps;
} & (
  | {
      pageTitle: React.ReactNode;
      pageIcon?: IconProps['as'];
      bannerExtraRight?: React.ReactNode;
      bannerExtraBottom?: React.ReactNode;
    }
  | {
      banner: React.ReactNode;
    }
);

export function AppLayout({
  fixedHeight,
  children,
  footer,
  ...props
}: AppLayoutProps) {
  const bgGradient = useGradient('background');
  const route = useRouter();
  const [needsASleep, aciLoggedToday] = useNeedsSleepNeedsACI();
  const { tabs } = useTabsContext();
  const { data: user } = useUserProfile();
  const learningState = $API.useGetHomepage(
    {},
    { select: (data) => data.learning_state },
  );

  if (user && footer === undefined) {
    footer = match(learningState.data)
      .with('launchpad', () => <LaunchpadFooter />)
      .otherwise(() => <AppFooter />);
  }

  const childContent = tabs?.length ? (
    <TabsContent>{children}</TabsContent>
  ) : (
    children
  );

  const bannerContent =
    'pageTitle' in props ? (
      <>
        <Flex gap="3" py="4" px={6} color="icon.primary" align="center">
          {props.pageIcon && <Icon as={props.pageIcon} boxSize="6" />}
          <Text textStyle={'h3'} mr={'auto'} textShadow="8dp">
            {props.pageTitle}
          </Text>
          {props.bannerExtraRight}
        </Flex>
        {props.bannerExtraBottom}
      </>
    ) : (
      props.banner
    );

  const footerProps = {
    gridArea: 'footer',
    tabs: tabs?.length ? <TabsList /> : null,
    sticky: !fixedHeight,
  };

  return (
    <AppContainer
      appLayout
      fixedHeight={fixedHeight}
      data-learning-state={learningState.data}
      bg={bgGradient}
    >
      <Box
        display={route.asPath.startsWith('/chat') ? 'none' : 'unset'}
        w={'full'}
      >
        {('pageTitle' in props ? false : props.banner === null ? true : false)
          ? null
          : bannerContent}
      </Box>

      <Box
        overflow={
          (needsASleep || !aciLoggedToday) &&
          route.asPath.startsWith('/progress/')
            ? 'hidden'
            : 'inherit'
        }
        gridArea="content-body"
        maxWidth="100vw"
        isolation="isolate"
        {...props.contentProps}
      >
        {childContent}
      </Box>

      {footer && cloneElement(footer, footerProps)}
    </AppContainer>
  );
}
