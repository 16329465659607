import {
  Button,
  chakra,
  Fade,
  Flex,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { AciContext, AciSelector, useACIGate } from '@arena-labs/strive2-data';
import { WaitForQuery, ZStack } from '@arena-labs/strive2-ui';
import { $API, Wearable } from '@strive/api';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

export function AciCTACard() {
  const { actions } = useACIGate();
  const userQuery = $API.useGetUserProfile();
  const analytics = useAnalytics();
  const aciActor = AciContext.useActorRef();

  const isLoading = AciContext.useSelector(AciSelector.isLoading);
  const isComplete = AciContext.useSelector(AciSelector.isComplete);
  const isSkipped = AciContext.useSelector(AciSelector.isSkipped);
  const canResume = AciContext.useSelector(AciSelector.canResume);

  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );

  const skipAci = () => {
    analytics.logEvent(DataTrackingEvent.ACISkipped);
    aciActor.send({ type: 'skip' });
  };

  const openAci = (wearable?: Wearable) => {
    if (wearable?.provider) {
      aciActor.send({ type: 'open' });
      analytics.logEvent(DataTrackingEvent.ACIModalOpened);
    } else {
      actions.handleStartCheckIn();
    }
  };

  const openAciInsights = (wearable?: Wearable) => {
    if (wearable?.provider) {
      aciActor.send({ type: 'open' });
    } else {
      actions.openToResults();
    }
    analytics.logEvent(DataTrackingEvent.ACIInsightsOpened);
  };

  const strivewareActor = StrivewareContext.useActorRef();
  const reconnect = StrivewareContext.useSelector((state) =>
    state.can({ type: 'Retry' })
      ? () => strivewareActor.send({ type: 'Retry' })
      : state.can({ type: 'Sync' })
      ? () => strivewareActor.send({ type: 'Sync' })
      : null,
  );

  return (
    <Fade
      in={!isLoading && userQuery.status === 'success'}
      style={{ display: 'contents' }}
    >
      <WaitForQuery query={userQuery}>
        {(user) => {
          const wearable = user.profile.wearable[0];
          const isSyncedToday =
            wearable?.is_synced_today || deviceState === 'synced';
          return (
            <ZStack
              w={'full'}
              bg={'neutral.white'}
              rounded={'card'}
              position={'relative'}
              zIndex={0}
              shadow={'1dp'}
              overflow={'hidden'}
              maxH={isSkipped || isComplete ? '175px' : 'auto'}
              minW={'300px'}
            >
              <Image
                ml={'auto'}
                src={'./images/aci-card-lines.webp'}
                opacity={0.2}
                alt={''}
                w={'66%'}
                h={'auto'}
                maxH={isSkipped || isComplete ? '150px' : 'auto'}
              />

              <VStack
                w="full"
                px={4}
                gap={2}
                pt="40px"
                pb="4"
                textAlign={'left'}
                position={'relative'}
                color={'neutral.black'}
              >
                {isSkipped ? (
                  <>
                    <Text
                      textStyle={'h3'}
                      color={'neutral.800'}
                      mr={'auto'}
                      mt={'auto'}
                    >
                      Arena Check-In skipped
                    </Text>
                    <Text
                      textStyle={'copy_small'}
                      lineHeight={'tall'}
                      color={'neutral.600'}
                    >
                      You decided to skip today. Come back tomorrow for a new
                      check-in!
                    </Text>
                  </>
                ) : isComplete ? (
                  <>
                    <Text
                      textStyle={'h2'}
                      color={'neutral.800'}
                      mr={'auto'}
                      mt={'auto'}
                    >
                      Arena Check-In completed
                    </Text>
                    <Button
                      variant={'underline-link'}
                      onClick={() => openAciInsights(wearable)}
                      color={'neutral.600'}
                    >
                      View today&apos;s insights
                    </Button>
                  </>
                ) : isSyncedToday ? (
                  <>
                    <Text
                      w={'full'}
                      textStyle={'h3'}
                      flexWrap={'nowrap'}
                      color={'neutral.800'}
                      mr={'auto'}
                      mt={'auto'}
                    >
                      Ring Synced Successfully!
                    </Text>
                    <Text
                      textStyle={'copy_small'}
                      w={'full'}
                      ßå
                      as={Flex}
                      gap={'1'}
                      å
                      alignContent={'flex-start'}
                      flexDirection={'column'}
                      lineHeight={'tall'}
                      color={'neutral.600'}
                    >
                      <chakra.span>
                        Did you wear your ring during sleep?
                      </chakra.span>
                      <chakra.span>
                        Full insights require sleep data from last night.
                      </chakra.span>
                    </Text>
                    <Button
                      w="full"
                      variant="primary"
                      onClick={() => openAci(wearable)}
                    >
                      {canResume ? 'Resume' : 'Start'} ACI
                    </Button>
                    <Button
                      variant="underline-link"
                      onClick={() => skipAci()}
                      textStyle="copy"
                      color={'neutral.600'}
                    >
                      Skip check-in and reveal data
                    </Button>
                  </>
                ) : deviceState === 'syncing' ||
                  deviceState === 'connecting' ? (
                  <>
                    <Text textStyle="h2" color="neutral.800">
                      Arena Check-In
                    </Text>
                    <Text textStyle="copy_small" color="neutral.600">
                      We&apos;re syncing your ring now to make sure you get the
                      most accurate results for your ACI.
                    </Text>
                    <Flex
                      w="full"
                      bg={'logo'}
                      direction="row"
                      align="center"
                      justify="center"
                      gap="2"
                      py="3"
                      borderRadius="card"
                    >
                      <Spinner size="sm" color="neutral.white" />
                      <Text textStyle="copy" color={'neutral.white'}>
                        {deviceState === 'connecting'
                          ? 'Connecting'
                          : 'Syncing data'}
                        ...
                      </Text>
                    </Flex>
                    <Button
                      variant="underline-link"
                      onClick={() => skipAci()}
                      color={'neutral.600'}
                    >
                      <Text textStyle="copy">
                        Skip check-in and reveal data
                      </Text>
                    </Button>
                  </>
                ) : (
                  <>
                    <Text
                      textStyle="h3"
                      color="neutral.800"
                      mt={'auto'}
                      w={'full'}
                      textAlign={'left'}
                    >
                      Connection Unsuccessful
                    </Text>
                    <Text textStyle="copy_small" color="neutral.600">
                      Ensure your ring is within 5-8 meters of your phone. If
                      the battery is low, place it on a charger to help
                      establish the connection.
                    </Text>
                    {reconnect && (
                      <Button
                        w="full"
                        variant="primary"
                        onClick={() => reconnect?.()}
                      >
                        Reconnect
                      </Button>
                    )}
                    <Button
                      w="full"
                      variant="primary"
                      onClick={() => openAci(wearable)}
                    >
                      {canResume ? 'Resume' : 'Start'} ACI
                    </Button>

                    <Button
                      variant="underline-link"
                      onClick={() => skipAci()}
                      color={'neutral.600'}
                    >
                      Skip check-in and reveal data
                    </Button>
                  </>
                )}
              </VStack>
            </ZStack>
          );
        }}
      </WaitForQuery>
    </Fade>
  );
}
